.DataDecoderContainer {
    margin-top: 50px;
    display: flex;
  }
  .DataDecoderHeroContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    
  }
  .DataDecoderHero {
    max-width: 100%;
    box-sizing: border-box;
    width: 100%;
    height: -moz-fit-content;
    height: fit-content;
    border-left: 2px solid #3bedb5;
    padding: 8px;
    padding-left: 20px;
    
  }
  .DataDecoderJSON {
    display: flex;
    align-items: baseline;
    margin-bottom: 15px;
    font-weight: 300;
    width: fit-content;
  }
  .DataDecoderJSON > p > span {
    font-weight: 700;
    
  }
  .DataDecoderInput {
    font-family: 'Inconsolata';
    font-size: 16px;
    font-weight: 300;
    width: 45px;
    height: 22px;
    padding-left: 5px;
    padding-top: 2px;
    border: none;
    background-color: rgba(32, 240, 146, 0.2);
    border-bottom: 2px solid #20f092;
    color: #ffffff;
    caret-color: #ffffff;
    
  }
  .DataDecoderInput:focus {
    outline-color: #20f092;
    outline-width: 1px;
    
  }
  .DataDecoderResults {
    max-width: 75%;
    box-sizing: border-box;
    width: 100%;
    height: fit-content;
    border: 2px solid #ffffff;
    padding: 12px 12px 0px 20px;
    margin-top: 30px;
  }